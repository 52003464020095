@font-face {
  font-family: 'ubnt';
  src:  url(/nms/fonts/ubnt-cb0101.eot);
  src:  url(/nms/fonts/ubnt-cb0101.eot#iefix) format('embedded-opentype'),
    url(/nms/fonts/ubnt-d6c856.woff2) format('woff2'),
    url(/nms/fonts/ubnt-b18016.ttf) format('truetype'),
    url(/nms/fonts/ubnt-aebd1f.woff) format('woff'),
    url(/nms/fonts/ubnt-574a28.svg#ubnt) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ubnt-icon--"], [class*=" ubnt-icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ubnt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ubnt-icon--edgerouter-6p:before {
  content: "\e9f4";
}
.ubnt-icon--edgeswitch-xp:before {
  content: "\e9f3";
}
.ubnt-icon--airfiber-5xhd:before {
  content: "\e9f2";
}
.ubnt-icon--unifi-hd:before {
  content: "\e9f1";
}
.ubnt-icon--uniFi-video-3g-pro:before {
  content: "\e9f5";
}
.ubnt-icon--ltu:before {
  content: "\e9f0";
}
.ubnt-icon--u-logo-ltu:before {
  content: "\e9ef";
}
.ubnt-icon--unms-mobile-black:before {
  content: "\e9ed";
}
.ubnt-icon--prism-ap-antenna:before {
  content: "\e9ee";
}
.ubnt-icon--NanoBeam-AC2:before {
  content: "\e9ec";
}
.ubnt-icon--nanostation-ac-loco:before {
  content: "\e9eb";
}
.ubnt-icon--wifi-3:before {
  content: "\e9ea";
}
.ubnt-icon--EdgeRouter-4:before {
  content: "\e9e9";
}
.ubnt-icon--UniFi-XG:before {
  content: "\e9e3";
}
.ubnt-icon--UniFi-XG-gateway:before {
  content: "\e9e4";
}
.ubnt-icon--UniFi-XG-mesh:before {
  content: "\e9e5";
}
.ubnt-icon--UniFi-XG-server:before {
  content: "\e9e6";
}
.ubnt-icon--UniFi-XG-stadium:before {
  content: "\e9e7";
}
.ubnt-icon--UniFi-XG-switch:before {
  content: "\e9e8";
}
.ubnt-icon--aircube:before {
  content: "\e980";
}
.ubnt-icon--airmax-ac-m-gpssync:before {
  content: "\e9bc";
}
.ubnt-icon--bypass-proxys-and-termials:before {
  content: "\e9bd";
}
.ubnt-icon--business:before {
  content: "\e9be";
}
.ubnt-icon--database .path1:before {
  content: "\e9bf";
  color: rgb(0, 0, 0);
}
.ubnt-icon--database .path2:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--database .path3:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ubnt-icon--database .path4:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--database .path5:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ubnt-icon--database .path6:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--database .path7:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ubnt-icon--file-transfer:before {
  content: "\e9c6";
}
.ubnt-icon--games .path1:before {
  content: "\e9c7";
  color: rgb(0, 0, 0);
}
.ubnt-icon--games .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ubnt-icon--games .path4:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path5:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path6:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path7:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path8:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path9:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path10:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path11:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--games .path12:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ubnt-icon--games .path13:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ubnt-icon--instant-messaging:before {
  content: "\e9d4";
}
.ubnt-icon--mail-and-collaboration:before {
  content: "\e9d5";
}
.ubnt-icon--network-management:before {
  content: "\e9d6";
}
.ubnt-icon--network-protocols:before {
  content: "\e9d7";
}
.ubnt-icon--p2p:before {
  content: "\e9d8";
}
.ubnt-icon--private-protocols:before {
  content: "\e9d9";
}
.ubnt-icon--remote-access-terminals:before {
  content: "\e9da";
}
.ubnt-icon--security-update:before {
  content: "\e9db";
}
.ubnt-icon--social-network:before {
  content: "\e9dc";
}
.ubnt-icon--stock-market:before {
  content: "\e9dd";
}
.ubnt-icon--streaming-media:before {
  content: "\e9de";
}
.ubnt-icon--unknown:before {
  content: "\e9df";
}
.ubnt-icon--voice-over-IP:before {
  content: "\e9e0";
}
.ubnt-icon--web-im:before {
  content: "\e9e1";
}
.ubnt-icon--web:before {
  content: "\e9e2";
}
.ubnt-icon--unifi-elite2:before {
  content: "\e9bb";
}
.ubnt-icon--airfiber-5-new:before {
  content: "\e9b4";
}
.ubnt-icon--airfiber-11fx-new:before {
  content: "\e9b5";
}
.ubnt-icon--airfiber-24hd-new:before {
  content: "\e9b6";
}
.ubnt-icon--airfiber-24-new-new:before {
  content: "\e9b7";
}
.ubnt-icon--airfiber-nxn-new:before {
  content: "\e9b8";
}
.ubnt-icon--airfiber-x-antenna-new:before {
  content: "\e9b9";
}
.ubnt-icon--airfiber-x-new-new:before {
  content: "\e9ba";
}
.ubnt-icon--EdgePoint-new:before {
  content: "\e99b";
}
.ubnt-icon--EdgeRouter-LITE-new:before {
  content: "\e99c";
}
.ubnt-icon--EdgeRouter-new:before {
  content: "\e99d";
}
.ubnt-icon--EdgeRouter-PoE-new:before {
  content: "\e99e";
}
.ubnt-icon--EdgeRouter-PRO-new:before {
  content: "\e99f";
}
.ubnt-icon--EdgeRouter-X-new:before {
  content: "\e9a0";
}
.ubnt-icon--edgeswitch-lite-new:before {
  content: "\e9a1";
}
.ubnt-icon--EdgeSwitch-new:before {
  content: "\e9a2";
}
.ubnt-icon--airGrid-M-new:before {
  content: "\e9a3";
}
.ubnt-icon--airMAX-Omni-new:before {
  content: "\e9a4";
}
.ubnt-icon--airMAX-Sector-new:before {
  content: "\e9a5";
}
.ubnt-icon--airPRISM-new:before {
  content: "\e9a6";
}
.ubnt-icon--crm-point-new:before {
  content: "\e9a7";
}
.ubnt-icon--IsoBeam-new:before {
  content: "\e9a8";
}
.ubnt-icon--litebeam-ac-new:before {
  content: "\e9a9";
}
.ubnt-icon--litebeam-m5-new:before {
  content: "\e9aa";
}
.ubnt-icon--NanoBeam-M-new:before {
  content: "\e9ab";
}
.ubnt-icon--NanoBridge-M-new:before {
  content: "\e9ac";
}
.ubnt-icon--NanoStation-M-new:before {
  content: "\e9ad";
}
.ubnt-icon--powerbeam-new:before {
  content: "\e9ae";
}
.ubnt-icon--rocket-ac-new:before {
  content: "\e9af";
}
.ubnt-icon--RocketDish-AC-new:before {
  content: "\e9b0";
}
.ubnt-icon--rocketdish-lw-new:before {
  content: "\e9b1";
}
.ubnt-icon--RocketDish-new:before {
  content: "\e9b2";
}
.ubnt-icon--rocket-M-new:before {
  content: "\e9b3";
}
.ubnt-icon--UniFi-AC-new:before {
  content: "\e99a";
}
.ubnt-icon--airGateway-new:before {
  content: "\e985";
}
.ubnt-icon--edgepower-logo-new:before {
  content: "\e986";
}
.ubnt-icon--fiber-poe-accessory-new:before {
  content: "\e987";
}
.ubnt-icon--PoE-adapters-new:before {
  content: "\e988";
}
.ubnt-icon--powercable-logo-new:before {
  content: "\e989";
}
.ubnt-icon--TOUGHCable-new:before {
  content: "\e98a";
}
.ubnt-icon--TOUGHSwitch-new:before {
  content: "\e98b";
}
.ubnt-icon--UniFi-AP-AC-EDU-new:before {
  content: "\e98c";
}
.ubnt-icon--UniFi-AP-AC-Lite-new:before {
  content: "\e98d";
}
.ubnt-icon--UniFi-AP-AC-LR-new:before {
  content: "\e98e";
}
.ubnt-icon--UniFi-AP-AC-PRO-new:before {
  content: "\e98f";
}
.ubnt-icon--UniFi-AP-new:before {
  content: "\e990";
}
.ubnt-icon--UniFi-AP-Outdoor-new:before {
  content: "\e991";
}
.ubnt-icon--unifi-cloud-key-new:before {
  content: "\e992";
}
.ubnt-icon--unifi-security-gateway-new:before {
  content: "\e993";
}
.ubnt-icon--unifi-security-gateway-pro-new:before {
  content: "\e994";
}
.ubnt-icon--unifi-switch-8-150w-new:before {
  content: "\e995";
}
.ubnt-icon--unifi-switch-8-new:before {
  content: "\e996";
}
.ubnt-icon--unifi-switch-16-150w-new:before {
  content: "\e997";
}
.ubnt-icon--UniFi-Switch-new:before {
  content: "\e998";
}
.ubnt-icon--unifi-voip-phone-new:before {
  content: "\e999";
}
.ubnt-icon--unifi-antenna:before {
  content: "\e984";
}
.ubnt-icon--uniFi-video-3g-micro:before {
  content: "\e983";
}
.ubnt-icon--nanoswitch:before {
  content: "\e982";
}
.ubnt-icon--unifi-ac-shd:before {
  content: "\e981";
}
.ubnt-icon--pause:before {
  content: "\e97a";
}
.ubnt-icon--pause-circle:before {
  content: "\e97b";
}
.ubnt-icon--play-3:before {
  content: "\e97c";
}
.ubnt-icon--play-circle:before {
  content: "\e97d";
}
.ubnt-icon--stop:before {
  content: "\e97e";
}
.ubnt-icon--stop-circle:before {
  content: "\e97f";
}
.ubnt-icon--prism-ap:before {
  content: "\e979";
}
.ubnt-icon--airMAX-AC-gen2:before {
  content: "\e978";
}
.ubnt-icon--unifi_server:before {
  content: "\e977";
}
.ubnt-icon--PowerModule:before {
  content: "\e976";
}
.ubnt-icon--network_ptmp:before {
  content: "\e970";
}
.ubnt-icon--network_ptp:before {
  content: "\e971";
}
.ubnt-icon--network_rural:before {
  content: "\e972";
}
.ubnt-icon--network_urban:before {
  content: "\e973";
}
.ubnt-icon--network_video:before {
  content: "\e974";
}
.ubnt-icon--network_woodland:before {
  content: "\e975";
}
.ubnt-icon--isostation-m5:before {
  content: "\e96f";
}
.ubnt-icon--gps-sync:before {
  content: "\e96e";
}
.ubnt-icon--u-fiber-nano-g:before {
  content: "\e96d";
}
.ubnt-icon--ufiber:before {
  content: "\e96c";
}
.ubnt-icon--aircube-logo:before {
  content: "\e969";
}
.ubnt-icon--configuration:before {
  content: "\e96a";
}
.ubnt-icon--site_survey:before {
  content: "\e96b";
}
.ubnt-icon--u-installer:before {
  content: "\e968";
}
.ubnt-icon--rocket-prism-ac:before {
  content: "\e967";
}
.ubnt-icon--unifi-video-camer-g3:before {
  content: "\e964";
}
.ubnt-icon--unifi-video-camer-g3-dome:before {
  content: "\e965";
}
.ubnt-icon--unifi-video-nvr-v2:before {
  content: "\e966";
}
.ubnt-icon--unifi-ac-inwall:before {
  content: "\e963";
}
.ubnt-icon--edgerouter-new:before {
  content: "\e961";
}
.ubnt-icon--edgerouter-x-new:before {
  content: "\e962";
}
.ubnt-icon--unifi-elite:before {
  content: "\e960";
}
.ubnt-icon--hotspot:before {
  content: "\e95f";
}
.ubnt-icon--isostation:before {
  content: "\e957";
}
.ubnt-icon--isostation-ac:before {
  content: "\e958";
}
.ubnt-icon--litebeam-gen2:before {
  content: "\e959";
}
.ubnt-icon--nanobeam-gen2:before {
  content: "\e95a";
}
.ubnt-icon--nanostation-ac:before {
  content: "\e95b";
}
.ubnt-icon--powerbeam-gen2:before {
  content: "\e95c";
}
.ubnt-icon--prismstation-ac:before {
  content: "\e95d";
}
.ubnt-icon--rocket-prism-gen2:before {
  content: "\e95e";
}
.ubnt-icon--performance:before {
  content: "\e954";
}
.ubnt-icon--multi-user:before {
  content: "\e955";
}
.ubnt-icon--clients-plus:before {
  content: "\e956";
}
.ubnt-icon--edgerouter-infinity:before {
  content: "\e953";
}
.ubnt-icon--unifi-ac-hd:before {
  content: "\e951";
}
.ubnt-icon--u-nms:before {
  content: "\e952";
}
.ubnt-icon--switch-ubnt:before {
  content: "\e950";
}
.ubnt-icon--nano-g:before {
  content: "\e94e";
}
.ubnt-icon--ufiber-olt:before {
  content: "\e94f";
}
.ubnt-icon--alert-1:before {
  content: "\e945";
}
.ubnt-icon--backup:before {
  content: "\e946";
}
.ubnt-icon--client:before {
  content: "\e947";
}
.ubnt-icon--clients:before {
  content: "\e948";
}
.ubnt-icon--console:before {
  content: "\e949";
}
.ubnt-icon--endpoints:before {
  content: "\e94a";
}
.ubnt-icon--log:before {
  content: "\e94b";
}
.ubnt-icon--sites:before {
  content: "\e94c";
}
.ubnt-icon--statistics-1:before {
  content: "\e94d";
}
.ubnt-icon--antenna:before {
  content: "\e944";
}
.ubnt-icon--stats-dotted:before {
  content: "\e943";
}
.ubnt-icon--legacy:before {
  content: "\e942";
}
.ubnt-icon--unifi-switch-8:before {
  content: "\e941";
}
.ubnt-icon--rotate-left:before {
  content: "\e93f";
}
.ubnt-icon--rotate-right:before {
  content: "\e940";
}
.ubnt-icon--file-picture:before {
  content: "\e93e";
}
.ubnt-icon--u-mobile:before {
  content: "\e93d";
}
.ubnt-icon--u-fiber:before {
  content: "\e93c";
}
.ubnt-icon--unifi-mesh:before {
  content: "\e93a";
}
.ubnt-icon--unifi-switch-16xg:before {
  content: "\e93b";
}
.ubnt-icon--edgecontrol-logo:before {
  content: "\e934";
}
.ubnt-icon--unms:before {
  content: "\e939";
}
.ubnt-icon--airfiber-11fx:before {
  content: "\e937";
}
.ubnt-icon--collapse:before {
  content: "\e936";
}
.ubnt-icon--close:before {
  content: "\e935";
}
.ubnt-icon--expand:before {
  content: "\e938";
}
.ubnt-icon--powercable-logo:before {
  content: "\e932";
}
.ubnt-icon--edgepower-logo:before {
  content: "\e933";
}
.ubnt-icon--enhance:before {
  content: "\e931";
}
.ubnt-icon--unifi-switch-poe:before {
  content: "\e930";
}
.ubnt-icon--edgeswitch-8-150w:before {
  content: "\e92c";
}
.ubnt-icon--unifi-app:before {
  content: "\e92d";
}
.ubnt-icon--unifi-edu-app:before {
  content: "\e92e";
}
.ubnt-icon--unifi-video-app:before {
  content: "\e92f";
}
.ubnt-icon--aircontrol:before {
  content: "\e929";
}
.ubnt-icon--airLink:before {
  content: "\e92a";
}
.ubnt-icon--airOS-mobile:before {
  content: "\e92b";
}
.ubnt-icon--air-fiber-edge-max:before {
  content: "\e926";
}
.ubnt-icon--sun2:before {
  content: "\e927";
}
.ubnt-icon--uniFi-video-3g:before {
  content: "\e928";
}
.ubnt-icon--u-crm:before {
  content: "\e925";
}
.ubnt-icon--restart:before {
  content: "\e924";
}
.ubnt-icon--guest-wired:before {
  content: "\e91e";
}
.ubnt-icon--guest-wireless:before {
  content: "\e91f";
}
.ubnt-icon--guest:before {
  content: "\e920";
}
.ubnt-icon--user-wired:before {
  content: "\e921";
}
.ubnt-icon--user-wireless:before {
  content: "\e922";
}
.ubnt-icon--user:before {
  content: "\e923";
}
.ubnt-icon--u-crm-billing:before {
  content: "\e91d";
}
.ubnt-icon--bars:before {
  content: "\e918";
}
.ubnt-icon--bluetooth-oval:before {
  content: "\e919";
}
.ubnt-icon--box-archive:before {
  content: "\e91a";
}
.ubnt-icon--microwave:before {
  content: "\e91b";
}
.ubnt-icon--tone:before {
  content: "\e91c";
}
.ubnt-icon--unifi-switch-8-150w:before {
  content: "\e916";
}
.ubnt-icon--unifi-switch-16-150w:before {
  content: "\e917";
}
.ubnt-icon--edgeswitch-12-fiber:before {
  content: "\e915";
}
.ubnt-icon--unifi-ap-in-wall:before {
  content: "\e913";
}
.ubnt-icon--fiber-poe-accessory:before {
  content: "\e914";
}
.ubnt-icon--edgeswitch-16xg:before {
  content: "\e911";
}
.ubnt-icon--edgeswitch-12f-150w:before {
  content: "\e912";
}
.ubnt-icon--ap-unifi:before {
  content: "\e910";
}
.ubnt-icon--filter-fill:before {
  content: "\e90e";
}
.ubnt-icon--filter:before {
  content: "\e90f";
}
.ubnt-icon--ul-logo:before {
  content: "\e90c";
}
.ubnt-icon--bluetooth:before {
  content: "\e90d";
}
.ubnt-icon--airgateway-ins:before {
  content: "\e90b";
}
.ubnt-icon--unifi-security-gateway-pro:before {
  content: "\e90a";
}
.ubnt-icon--lens-correction:before {
  content: "\e909";
}
.ubnt-icon--airmagic:before {
  content: "\e907";
}
.ubnt-icon--unifi-ap-ac-in-wall:before {
  content: "\e908";
}
.ubnt-icon--reset:before {
  content: "\e905";
}
.ubnt-icon--isp:before {
  content: "\e906";
}
.ubnt-icon--denoise:before {
  content: "\e900";
}
.ubnt-icon--hue:before {
  content: "\e901";
}
.ubnt-icon--aemode:before {
  content: "\e902";
}
.ubnt-icon--sharpness:before {
  content: "\e903";
}
.ubnt-icon--clipboard:before {
  content: "\e904";
}
.ubnt-icon--stamp:before {
  content: "\e803";
}
.ubnt-icon--map-pins:before {
  content: "\e802";
}
.ubnt-icon--rocket5ac-prism-01:before {
  content: "\e800";
}
.ubnt-icon--airfiber-nxn:before {
  content: "\e801";
}
.ubnt-icon--litebeam-ac:before {
  content: "\e7c2";
}
.ubnt-icon--litebeam-m5:before {
  content: "\e7a2";
}
.ubnt-icon--sunlink:before {
  content: "\e7c0";
}
.ubnt-icon--sunmax:before {
  content: "\e7c1";
}
.ubnt-icon--panels:before {
  content: "\e7be";
}
.ubnt-icon--dc-power:before {
  content: "\e7bf";
}
.ubnt-icon--crm-point:before {
  content: "\e7bd";
}
.ubnt-icon--controller:before {
  content: "\e7bb";
}
.ubnt-icon--cloud-key:before {
  content: "\e7bc";
}
.ubnt-icon--unifi-controller:before {
  content: "\e7ba";
}
.ubnt-icon--unifi-cloud-key:before {
  content: "\e7b9";
}
.ubnt-icon--unifi-ac:before {
  content: "\e7b8";
}
.ubnt-icon--crm-control:before {
  content: "\e7b7";
}
.ubnt-icon--organizations-manager:before {
  content: "\e7b6";
}
.ubnt-icon--unifi_ap_ac_edu:before {
  content: "\e7b5";
}
.ubnt-icon--info-fill:before {
  content: "\e7af";
}
.ubnt-icon--eye-fill:before {
  content: "\e7b0";
}
.ubnt-icon--check-circle-fill:before {
  content: "\e7b1";
}
.ubnt-icon--lightning-bolt-fill:before {
  content: "\e7b2";
}
.ubnt-icon--pointer-double-right:before {
  content: "\e7b3";
}
.ubnt-icon--lines-step-up:before {
  content: "\e7b4";
}
.ubnt-icon--innerfeed:before {
  content: "\e6c5";
}
.ubnt-icon--powerbeam-ac:before {
  content: "\e7a3";
}
.ubnt-icon--powerbeam-m5-iso:before {
  content: "\e6d1";
}
.ubnt-icon--powerbeam-ac-iso:before {
  content: "\e765";
}
.ubnt-icon--powerbeam:before {
  content: "\e7a4";
}
.ubnt-icon--fullscreen-exit:before {
  content: "\e7ae";
}
.ubnt-icon--edgeswitch-lite-24:before {
  content: "\e7ab";
}
.ubnt-icon--edgeswitch-lite-48:before {
  content: "\e7ac";
}
.ubnt-icon--edgeswitch-lite:before {
  content: "\e7ad";
}
.ubnt-icon--pencil:before {
  content: "\e6e4";
}
.ubnt-icon--world:before {
  content: "\e7aa";
}
.ubnt-icon--keyboard:before {
  content: "\e7a8";
}
.ubnt-icon--popout:before {
  content: "\e7a9";
}
.ubnt-icon--unifi-ap-ac-pro:before {
  content: "\e7a5";
}
.ubnt-icon--unifi-ap-ac-lr:before {
  content: "\e7a6";
}
.ubnt-icon--unifi-ap-ac-lite:before {
  content: "\e7a7";
}
.ubnt-icon--mobile-phone:before {
  content: "\e79e";
}
.ubnt-icon--signal-offline:before {
  content: "\e79f";
}
.ubnt-icon--sine-wave-alert:before {
  content: "\e7a0";
}
.ubnt-icon--sine-wave-offline:before {
  content: "\e7a1";
}
.ubnt-icon--aircrm-operations-beta:before {
  content: "\e79c";
}
.ubnt-icon--aircrm-operations:before {
  content: "\e79d";
}
.ubnt-icon--edgepoint:before {
  content: "\e79b";
}
.ubnt-icon--arrow-status-high:before {
  content: "\e77d";
}
.ubnt-icon--arrow-status-normal:before {
  content: "\e77e";
}
.ubnt-icon--arrow-status-low:before {
  content: "\e799";
}
.ubnt-icon--arrow-status-critical:before {
  content: "\e79a";
}
.ubnt-icon--edgebox:before {
  content: "\e77c";
}
.ubnt-icon--x-circle:before {
  content: "\e798";
}
.ubnt-icon--precision-alignment-kit:before {
  content: "\e77f";
}
.ubnt-icon--rocketdish-lw:before {
  content: "\e780";
}
.ubnt-icon--isobeam:before {
  content: "\e781";
}
.ubnt-icon--edgerouter-x-sfp:before {
  content: "\e782";
}
.ubnt-icon--edgerouter-x:before {
  content: "\e783";
}
.ubnt-icon--alert-fill:before {
  content: "\e784";
}
.ubnt-icon--minus:before {
  content: "\e785";
}
.ubnt-icon--airfiber-x-antenna:before {
  content: "\e786";
}
.ubnt-icon--airfiber-5x:before {
  content: "\e787";
}
.ubnt-icon--check-circle2:before {
  content: "\e788";
}
.ubnt-icon--airfiber-antenna:before {
  content: "\e789";
}
.ubnt-icon--airfiber-x:before {
  content: "\e78a";
}
.ubnt-icon--email2:before {
  content: "\e78b";
}
.ubnt-icon--network-drive-small:before {
  content: "\e78c";
}
.ubnt-icon--lock-open:before {
  content: "\e78d";
}
.ubnt-icon--lock:before {
  content: "\e78e";
}
.ubnt-icon--lock-positive:before {
  content: "\e78f";
}
.ubnt-icon--lock-alert:before {
  content: "\e790";
}
.ubnt-icon--cloud2:before {
  content: "\e791";
}
.ubnt-icon--airfiber-5t:before {
  content: "\e792";
}
.ubnt-icon--unifi-video-camera-micro:before {
  content: "\e793";
}
.ubnt-icon--eye22:before {
  content: "\e794";
}
.ubnt-icon--apple-outline2:before {
  content: "\e795";
}
.ubnt-icon--desktop-monitor2:before {
  content: "\e796";
}
.ubnt-icon--share-android2:before {
  content: "\e797";
}
.ubnt-icon--airfiber-24hd:before {
  content: "\e755";
}
.ubnt-icon--rocket-ac:before {
  content: "\e77b";
}
.ubnt-icon--arrows-updown-leftright:before {
  content: "\e777";
}
.ubnt-icon--clone-marker:before {
  content: "\e775";
}
.ubnt-icon--creditcard:before {
  content: "\e776";
}
.ubnt-icon--arrow-horizontal-vertical:before {
  content: "\e77a";
}
.ubnt-icon--arrows-diagonal:before {
  content: "\e778";
}
.ubnt-icon--camera:before {
  content: "\e779";
}
.ubnt-icon--cloud-storm:before {
  content: "\e76e";
}
.ubnt-icon--cloud:before {
  content: "\e76f";
}
.ubnt-icon--apple:before {
  content: "\e770";
}
.ubnt-icon--android-solid:before {
  content: "\e771";
}
.ubnt-icon--list-unordered:before {
  content: "\e772";
}
.ubnt-icon--add-simulation:before {
  content: "\e773";
}
.ubnt-icon--layers:before {
  content: "\e774";
}
.ubnt-icon--leaf:before {
  content: "\e763";
}
.ubnt-icon--location-2:before {
  content: "\e764";
}
.ubnt-icon--dollar-sign-plus:before {
  content: "\e766";
}
.ubnt-icon--bookmark-star:before {
  content: "\e767";
}
.ubnt-icon--pdf:before {
  content: "\e768";
}
.ubnt-icon--bookmark:before {
  content: "\e769";
}
.ubnt-icon--flag:before {
  content: "\e76a";
}
.ubnt-icon--printer:before {
  content: "\e76b";
}
.ubnt-icon--tag:before {
  content: "\e76c";
}
.ubnt-icon--documentdouble:before {
  content: "\e76d";
}
.ubnt-icon--airCRM-Billing-beta:before {
  content: "\e75e";
}
.ubnt-icon--billing-app-icon:before {
  content: "\e75f";
}
.ubnt-icon--control-app-icon:before {
  content: "\e760";
}
.ubnt-icon--airCRM-Control-beta:before {
  content: "\e761";
}
.ubnt-icon--airCRM-beta:before {
  content: "\e762";
}
.ubnt-icon--signal:before {
  content: "\e75b";
}
.ubnt-icon--length:before {
  content: "\e75c";
}
.ubnt-icon--radio:before {
  content: "\e75d";
}
.ubnt-icon--chipset:before {
  content: "\e754";
}
.ubnt-icon--chart:before {
  content: "\e756";
}
.ubnt-icon--rx:before {
  content: "\e757";
}
.ubnt-icon--tx:before {
  content: "\e758";
}
.ubnt-icon--chart-signal-1:before {
  content: "\e759";
}
.ubnt-icon--chart-signal-2:before {
  content: "\e75a";
}
.ubnt-icon--sfp-port:before {
  content: "\e74f";
}
.ubnt-icon--shield-check:before {
  content: "\e750";
}
.ubnt-icon--eye:before {
  content: "\e751";
}
.ubnt-icon--unifi-switch:before {
  content: "\e752";
}
.ubnt-icon--trashcan:before {
  content: "\e753";
}
.ubnt-icon--airmax-device-offline:before {
  content: "\e6df";
}
.ubnt-icon--airmax-device:before {
  content: "\e74d";
}
.ubnt-icon--ap-offline:before {
  content: "\e74e";
}
.ubnt-icon--plus:before {
  content: "\e748";
}
.ubnt-icon--airCRM-Market:before {
  content: "\e749";
}
.ubnt-icon--airCRM-Billing:before {
  content: "\e74a";
}
.ubnt-icon--airCRM-Control:before {
  content: "\e74b";
}
.ubnt-icon--airCRM:before {
  content: "\e74c";
}
.ubnt-icon--RocketDish-AC:before {
  content: "\e743";
}
.ubnt-icon--key:before {
  content: "\e744";
}
.ubnt-icon--email:before {
  content: "\e745";
}
.ubnt-icon--login:before {
  content: "\e746";
}
.ubnt-icon--logout:before {
  content: "\e747";
}
.ubnt-icon--ethernet-surge-protector:before {
  content: "\e736";
}
.ubnt-icon--NanoBeam-AC:before {
  content: "\e741";
}
.ubnt-icon--airMAX-AC-Sector:before {
  content: "\e742";
}
.ubnt-icon--unifi-security-gateway:before {
  content: "\e735";
}
.ubnt-icon--header-maximize:before {
  content: "\e60b";
}
.ubnt-icon--header-minimize:before {
  content: "\e60d";
}
.ubnt-icon--chipset-x2:before {
  content: "\e731";
}
.ubnt-icon--android:before {
  content: "\e732";
}
.ubnt-icon--multi-touch:before {
  content: "\e733";
}
.ubnt-icon--shield-checkered:before {
  content: "\e734";
}
.ubnt-icon--calendar-day:before {
  content: "\e737";
}
.ubnt-icon--calendar-week:before {
  content: "\e738";
}
.ubnt-icon--calendar-month:before {
  content: "\e739";
}
.ubnt-icon--dollar-sign-2:before {
  content: "\e73a";
}
.ubnt-icon--arrows-downup:before {
  content: "\e73b";
}
.ubnt-icon--grid:before {
  content: "\e73c";
}
.ubnt-icon--block:before {
  content: "\e73d";
}
.ubnt-icon--auto:before {
  content: "\e73e";
}
.ubnt-icon--manual:before {
  content: "\e73f";
}
.ubnt-icon--lock-2:before {
  content: "\e740";
}
.ubnt-icon--airplay:before {
  content: "\e728";
}
.ubnt-icon--sliders:before {
  content: "\e729";
}
.ubnt-icon--infrared:before {
  content: "\e72a";
}
.ubnt-icon--register:before {
  content: "\e72b";
}
.ubnt-icon--sign-in:before {
  content: "\e72c";
}
.ubnt-icon--flip-horizontal:before {
  content: "\e72d";
}
.ubnt-icon--flip-vertical:before {
  content: "\e72e";
}
.ubnt-icon--rotate:before {
  content: "\e72f";
}
.ubnt-icon--axis:before {
  content: "\e730";
}
.ubnt-icon--unifi-voip-phone:before {
  content: "\e718";
}
.ubnt-icon--diamond:before {
  content: "\e719";
}
.ubnt-icon--target-2:before {
  content: "\e71a";
}
.ubnt-icon--infrared2:before {
  content: "\e71b";
}
.ubnt-icon--saturation:before {
  content: "\e71c";
}
.ubnt-icon--sun:before {
  content: "\e71d";
}
.ubnt-icon--contrast:before {
  content: "\e71e";
}
.ubnt-icon--brightness:before {
  content: "\e71f";
}
.ubnt-icon--magnify-minus:before {
  content: "\e720";
}
.ubnt-icon--magnify-plus:before {
  content: "\e721";
}
.ubnt-icon--camera-record:before {
  content: "\e722";
}
.ubnt-icon--fullscreen:before {
  content: "\e723";
}
.ubnt-icon--trash:before {
  content: "\e724";
}
.ubnt-icon--circle:before {
  content: "\e725";
}
.ubnt-icon--check-circle:before {
  content: "\e726";
}
.ubnt-icon--plus-circle:before {
  content: "\e727";
}
.ubnt-icon--geolocation:before {
  content: "\e717";
}
.ubnt-icon--brain:before {
  content: "\e715";
}
.ubnt-icon--dumbell:before {
  content: "\e716";
}
.ubnt-icon--door-open:before {
  content: "\e6a0";
}
.ubnt-icon--airfiber:before {
  content: "\e69b";
}
.ubnt-icon--unifi:before {
  content: "\e69d";
}
.ubnt-icon--airmax:before {
  content: "\e69e";
}
.ubnt-icon--zero-handoff-roaming:before {
  content: "\e69f";
}
.ubnt-icon--unifi-video:before {
  content: "\e69a";
}
.ubnt-icon--logotype:before {
  content: "\e697";
}
.ubnt-icon--unifi-video-camera-dome:before {
  content: "\e698";
}
.ubnt-icon--airMAX-omni:before {
  content: "\e699";
}
.ubnt-icon--link:before {
  content: "\e68d";
}
.ubnt-icon--NanoBeam-M:before {
  content: "\e68e";
}
.ubnt-icon--logo-lockup:before {
  content: "\e68f";
}
.ubnt-icon--ubnt-logo-u:before {
  content: "\e690";
}
.ubnt-icon--cloud-add:before {
  content: "\e691";
}
.ubnt-icon--UWN:before {
  content: "\e692";
}
.ubnt-icon--UWN-horizontal:before {
  content: "\e693";
}
.ubnt-icon--airGrid-M:before {
  content: "\e694";
}
.ubnt-icon--airMAX-Omni:before {
  content: "\e695";
}
.ubnt-icon--airMAX-Yagi:before {
  content: "\e696";
}
.ubnt-icon--dolly:before {
  content: "\e600";
}
.ubnt-icon--UniFi-Appliance:before {
  content: "\e601";
}
.ubnt-icon--play-2:before {
  content: "\e602";
}
.ubnt-icon--day:before {
  content: "\e603";
}
.ubnt-icon--droplet:before {
  content: "\e604";
}
.ubnt-icon--lightning-bolt:before {
  content: "\e605";
}
.ubnt-icon--thermometer-mid:before {
  content: "\e606";
}
.ubnt-icon--thermometer-low:before {
  content: "\e607";
}
.ubnt-icon--thermometer-high:before {
  content: "\e608";
}
.ubnt-icon--user-legacy:before {
  content: "\e609";
}
.ubnt-icon--bulb:before {
  content: "\e60a";
}
.ubnt-icon--energy-savings:before {
  content: "\e60c";
}
.ubnt-icon--airOSX-billing:before {
  content: "\e60e";
}
.ubnt-icon--airOSX-market:before {
  content: "\e60f";
}
.ubnt-icon--airOSX-control:before {
  content: "\e610";
}
.ubnt-icon--mfi:before {
  content: "\e611";
}
.ubnt-icon--magnifying-glass:before {
  content: "\e612";
}
.ubnt-icon--news:before {
  content: "\e614";
}
.ubnt-icon--phone:before {
  content: "\e615";
}
.ubnt-icon--info:before {
  content: "\e616";
}
.ubnt-icon--dish-angled:before {
  content: "\e617";
}
.ubnt-icon--TOUGHSwitch:before {
  content: "\e618";
}
.ubnt-icon--UniFi-Appliance2:before {
  content: "\e619";
}
.ubnt-icon--play-22:before {
  content: "\e61a";
}
.ubnt-icon--unifi-video-nvr:before {
  content: "\e61b";
}
.ubnt-icon--unifi-video-camera:before {
  content: "\e61c";
}
.ubnt-icon--unifi-video-camera-pro:before {
  content: "\e61d";
}
.ubnt-icon--unifi-video-camera-dome2:before {
  content: "\e61e";
}
.ubnt-icon--PowerBridge-M10:before {
  content: "\e61f";
}
.ubnt-icon--EdgeSwitch:before {
  content: "\e620";
}
.ubnt-icon--arrow-down-4:before {
  content: "\e621";
}
.ubnt-icon--UniFi-AP-Outdoor-Plus:before {
  content: "\e622";
}
.ubnt-icon--arrow-up:before {
  content: "\e623";
}
.ubnt-icon--arrow-down:before {
  content: "\e624";
}
.ubnt-icon--arrows-sort:before {
  content: "\e625";
}
.ubnt-icon--UniFi-AP-Outdoor:before {
  content: "\e626";
}
.ubnt-icon--lightning-bolt2:before {
  content: "\e627";
}
.ubnt-icon--arrow-right-4:before {
  content: "\e628";
}
.ubnt-icon--arrow-left-4:before {
  content: "\e629";
}
.ubnt-icon--arrow-up-4:before {
  content: "\e62a";
}
.ubnt-icon--mSensor:before {
  content: "\e62b";
}
.ubnt-icon--rain:before {
  content: "\e62c";
}
.ubnt-icon--day-night:before {
  content: "\e62d";
}
.ubnt-icon--microphone:before {
  content: "\e62e";
}
.ubnt-icon--snowflake:before {
  content: "\e62f";
}
.ubnt-icon--hard-drive:before {
  content: "\e630";
}
.ubnt-icon--camera-add:before {
  content: "\e631";
}
.ubnt-icon--camera-hd:before {
  content: "\e632";
}
.ubnt-icon--wide-angle:before {
  content: "\e633";
}
.ubnt-icon--asterisk-light:before {
  content: "\e634";
}
.ubnt-icon--document:before {
  content: "\e635";
}
.ubnt-icon--bridge:before {
  content: "\e636";
}
.ubnt-icon--UniFi-AP-Outdoor-5:before {
  content: "\e637";
}
.ubnt-icon--airOSX-market2:before {
  content: "\e638";
}
.ubnt-icon--airOSX-control2:before {
  content: "\e639";
}
.ubnt-icon--airOSX-billing2:before {
  content: "\e63a";
}
.ubnt-icon--pencil-2:before {
  content: "\e63b";
}
.ubnt-icon--calendar-3:before {
  content: "\e63c";
}
.ubnt-icon--arrows-range:before {
  content: "\e63d";
}
.ubnt-icon--wave-frequency:before {
  content: "\e63e";
}
.ubnt-icon--ethernet:before {
  content: "\e63f";
}
.ubnt-icon--ethernet-port:before {
  content: "\e640";
}
.ubnt-icon--play:before {
  content: "\e641";
}
.ubnt-icon--rocket-M:before {
  content: "\e642";
}
.ubnt-icon--rocket-M5-AC:before {
  content: "\e643";
}
.ubnt-icon--airMAX-AC:before {
  content: "\e644";
}
.ubnt-icon--NanoBeam-M5-AC:before {
  content: "\e645";
}
.ubnt-icon--switch:before {
  content: "\e646";
}
.ubnt-icon--box:before {
  content: "\e647";
}
.ubnt-icon--routing:before {
  content: "\e648";
}
.ubnt-icon--phone-3:before {
  content: "\e649";
}
.ubnt-icon--UniFi-VoIP:before {
  content: "\e64a";
}
.ubnt-icon--UniFi-AP:before {
  content: "\e64b";
}
.ubnt-icon--UniFi-AP-PRO:before {
  content: "\e64c";
}
.ubnt-icon--UniFi-AP-LR:before {
  content: "\e64d";
}
.ubnt-icon--UniFi-AP-AC-Outdoor:before {
  content: "\e64e";
}
.ubnt-icon--UniFi-AP-AC:before {
  content: "\e64f";
}
.ubnt-icon--TOUGHSwitch-PoE:before {
  content: "\e650";
}
.ubnt-icon--TOUGHCable:before {
  content: "\e651";
}
.ubnt-icon--airGrid-M2:before {
  content: "\e652";
}
.ubnt-icon--NanoBridge-M:before {
  content: "\e653";
}
.ubnt-icon--NanoBeam-M2:before {
  content: "\e654";
}
.ubnt-icon--mPower:before {
  content: "\e655";
}
.ubnt-icon--mPort:before {
  content: "\e656";
}
.ubnt-icon--RocketDish:before {
  content: "\e657";
}
.ubnt-icon--PowerBridge-M:before {
  content: "\e658";
}
.ubnt-icon--NanoStation-M:before {
  content: "\e659";
}
.ubnt-icon--EdgeRouter-pro:before {
  content: "\e65a";
}
.ubnt-icon--EdgeRouter-poe:before {
  content: "\e65b";
}
.ubnt-icon--EdgeRouter-lite:before {
  content: "\e65c";
}
.ubnt-icon--airfiber-5:before {
  content: "\e65d";
}
.ubnt-icon--airCam-pro:before {
  content: "\e65e";
}
.ubnt-icon--airCam-IR:before {
  content: "\e65f";
}
.ubnt-icon--airCam-IR-Dome:before {
  content: "\e660";
}
.ubnt-icon--EdgeRouter:before {
  content: "\e661";
}
.ubnt-icon--bullet-m:before {
  content: "\e662";
}
.ubnt-icon--airMAX-titanium-sector:before {
  content: "\e663";
}
.ubnt-icon--airMAX-sector:before {
  content: "\e664";
}
.ubnt-icon--airMAX-omni2:before {
  content: "\e665";
}
.ubnt-icon--airGateway:before {
  content: "\e666";
}
.ubnt-icon--rocket-M2:before {
  content: "\e667";
}
.ubnt-icon--PicoStation-M:before {
  content: "\e668";
}
.ubnt-icon--instant-8023af:before {
  content: "\e669";
}
.ubnt-icon--airfiber-24:before {
  content: "\e66a";
}
.ubnt-icon--airMAX-Yagi2:before {
  content: "\e66b";
}
.ubnt-icon--PoE-adapters:before {
  content: "\e66c";
}
.ubnt-icon--universal-arm-bracket:before {
  content: "\e66d";
}
.ubnt-icon--NanoStation-Mount:before {
  content: "\e66e";
}
.ubnt-icon--multilane-rf:before {
  content: "\e66f";
}
.ubnt-icon--doorhang:before {
  content: "\e670";
}
.ubnt-icon--ap:before {
  content: "\e671";
}
.ubnt-icon--questionmark:before {
  content: "\e672";
}
.ubnt-icon--facebook:before {
  content: "\e673";
}
.ubnt-icon--single-airos:before {
  content: "\e674";
}
.ubnt-icon--waterfall:before {
  content: "\e675";
}
.ubnt-icon--HDD:before {
  content: "\e676";
}
.ubnt-icon--airPRISM:before {
  content: "\e677";
}
.ubnt-icon--airmax2:before {
  content: "\e678";
}
.ubnt-icon--airBeam:before {
  content: "\e679";
}
.ubnt-icon--airView:before {
  content: "\e67a";
}
.ubnt-icon--current:before {
  content: "\e67b";
}
.ubnt-icon--pamphlet:before {
  content: "\e67c";
}
.ubnt-icon--datasheet:before {
  content: "\e67d";
}
.ubnt-icon--backhaul-single:before {
  content: "\e67e";
}
.ubnt-icon--UWN2:before {
  content: "\e67f";
}
.ubnt-icon--minus-circle:before {
  content: "\e680";
}
.ubnt-icon--arrow-drag:before {
  content: "\e681";
}
.ubnt-icon--blank:before {
  content: "\e682";
}
.ubnt-icon--briefcase:before {
  content: "\e683";
}
.ubnt-icon--audio-on:before {
  content: "\e684";
}
.ubnt-icon--audio-off:before {
  content: "\e685";
}
.ubnt-icon--cast:before {
  content: "\e686";
}
.ubnt-icon--markup:before {
  content: "\e687";
}
.ubnt-icon--rss:before {
  content: "\e688";
}
.ubnt-icon--unifi_video22:before {
  content: "\e689";
}
.ubnt-icon--unifi-security:before {
  content: "\e68a";
}
.ubnt-icon--ellipsis:before {
  content: "\e68b";
}
.ubnt-icon--airOS5:before {
  content: "\e68c";
}
.ubnt-icon--disc-2:before {
  content: "\e69c";
}
.ubnt-icon--certificate:before {
  content: "\e6a1";
}
.ubnt-icon--lock-22:before {
  content: "\e6a2";
}
.ubnt-icon--unlock-2:before {
  content: "\e6a3";
}
.ubnt-icon--L3:before {
  content: "\e6a4";
}
.ubnt-icon--shield:before {
  content: "\e6a5";
}
.ubnt-icon--ubnt-logo-u2:before {
  content: "\e6a6";
}
.ubnt-icon--airOS:before {
  content: "\e6a7";
}
.ubnt-icon--airOS7:before {
  content: "\e6a8";
}
.ubnt-icon--UWN22:before {
  content: "\e6a9";
}
.ubnt-icon--airOSX:before {
  content: "\e6aa";
}
.ubnt-icon--audio-on2:before {
  content: "\e6ab";
}
.ubnt-icon--audio-off2:before {
  content: "\e6ac";
}
.ubnt-icon--asterisk:before {
  content: "\e6ad";
}
.ubnt-icon--logical:before {
  content: "\e6ae";
}
.ubnt-icon--unifi_video222:before {
  content: "\e6af";
}
.ubnt-icon--speedometer:before {
  content: "\e6b0";
}
.ubnt-icon--speedometer-high:before {
  content: "\e6b1";
}
.ubnt-icon--checkboxes:before {
  content: "\e6b2";
}
.ubnt-icon--tools:before {
  content: "\e6b3";
}
.ubnt-icon--wifi-2:before {
  content: "\e6b4";
}
.ubnt-icon--network:before {
  content: "\e6b5";
}
.ubnt-icon--map-2:before {
  content: "\e6b6";
}
.ubnt-icon--record:before {
  content: "\e6b7";
}
.ubnt-icon--camera-2:before {
  content: "\e6b8";
}
.ubnt-icon--book-2:before {
  content: "\e6b9";
}
.ubnt-icon--graph-3:before {
  content: "\e6ba";
}
.ubnt-icon--target:before {
  content: "\e6bb";
}
.ubnt-icon--cast2:before {
  content: "\e6bc";
}
.ubnt-icon--live-view:before {
  content: "\e6bd";
}
.ubnt-icon--users:before {
  content: "\e6be";
}
.ubnt-icon--firmware:before {
  content: "\e6bf";
}
.ubnt-icon--chat-bubbles-2:before {
  content: "\e6c0";
}
.ubnt-icon--logotype2:before {
  content: "\e6c1";
}
.ubnt-icon--ap2:before {
  content: "\e6c2";
}
.ubnt-icon--cube:before {
  content: "\e6c3";
}
.ubnt-icon--cart:before {
  content: "\e6c4";
}
.ubnt-icon--pdf2:before {
  content: "\e6c6";
}
.ubnt-icon--rss2:before {
  content: "\e6c7";
}
.ubnt-icon--excel:before {
  content: "\e6c8";
}
.ubnt-icon--xbrl:before {
  content: "\e6c9";
}
.ubnt-icon--wrench-2:before {
  content: "\e6ca";
}
.ubnt-icon--download-2:before {
  content: "\e6cb";
}
.ubnt-icon--x:before {
  content: "\e6cc";
}
.ubnt-icon--pointer-up:before {
  content: "\e6cd";
}
.ubnt-icon--pointer-right:before {
  content: "\e6ce";
}
.ubnt-icon--pointer-left:before {
  content: "\e6cf";
}
.ubnt-icon--pointer-down:before {
  content: "\e6d0";
}
.ubnt-icon--check-2:before {
  content: "\e613";
}
.ubnt-icon--sort:before {
  content: "\e6d2";
}
.ubnt-icon--send:before {
  content: "\e6d3";
}
.ubnt-icon--search-2:before {
  content: "\e6d4";
}
.ubnt-icon--select-check:before {
  content: "\e6d5";
}
.ubnt-icon--select-unchecked:before {
  content: "\e6d6";
}
.ubnt-icon--firmware-download:before {
  content: "\e6d7";
}
.ubnt-icon--preferences:before {
  content: "\e6d8";
}
.ubnt-icon--menu:before {
  content: "\e6d9";
}
.ubnt-icon--stopwatch:before {
  content: "\e6da";
}
.ubnt-icon--signal-low:before {
  content: "\e6db";
}
.ubnt-icon--power:before {
  content: "\e6dc";
}
.ubnt-icon--refresh-2:before {
  content: "\e6dd";
}
.ubnt-icon--sync:before {
  content: "\e6de";
}
.ubnt-icon--network-drive:before {
  content: "\e6e0";
}
.ubnt-icon--eye2:before {
  content: "\e6e1";
}
.ubnt-icon--envelope:before {
  content: "\e6e2";
}
.ubnt-icon--clock-2:before {
  content: "\e6e3";
}
.ubnt-icon--statistics:before {
  content: "\e6e5";
}
.ubnt-icon--firmware-update:before {
  content: "\e6e6";
}
.ubnt-icon--ap-airos:before {
  content: "\e6e7";
}
.ubnt-icon--backhaul:before {
  content: "\e6e8";
}
.ubnt-icon--house:before {
  content: "\e6e9";
}
.ubnt-icon--alert:before {
  content: "\e6ea";
}
.ubnt-icon--internet-offline:before {
  content: "\e6eb";
}
.ubnt-icon--internet:before {
  content: "\e6ec";
}
.ubnt-icon--question:before {
  content: "\e6ee";
}
.ubnt-icon--statistics-low:before {
  content: "\e6ef";
}
.ubnt-icon--speedometer-low:before {
  content: "\e6f0";
}
.ubnt-icon--link-broken:before {
  content: "\e6f1";
}
.ubnt-icon--gears:before {
  content: "\e6f2";
}
.ubnt-icon--gear-3:before {
  content: "\e6f3";
}
.ubnt-icon--youtube:before {
  content: "\e6f4";
}
.ubnt-icon--unifisecurity:before {
  content: "\e6f5";
}
.ubnt-icon--unifi2:before {
  content: "\e6f6";
}
.ubnt-icon--twitter:before {
  content: "\e6f7";
}
.ubnt-icon--news2:before {
  content: "\e6f8";
}
.ubnt-icon--mfi2:before {
  content: "\e6f9";
}
.ubnt-icon--instagram:before {
  content: "\e6fa";
}
.ubnt-icon--headset2:before {
  content: "\e6fb";
}
.ubnt-icon--graph2:before {
  content: "\e6fc";
}
.ubnt-icon--facebook2:before {
  content: "\e6fd";
}
.ubnt-icon--edgemax:before {
  content: "\e6fe";
}
.ubnt-icon--cap:before {
  content: "\e6ff";
}
.ubnt-icon--buildings:before {
  content: "\e700";
}
.ubnt-icon--box2:before {
  content: "\e701";
}
.ubnt-icon--airmax22:before {
  content: "\e702";
}
.ubnt-icon--airfiber2:before {
  content: "\e703";
}
.ubnt-icon--videos:before {
  content: "\e704";
}
.ubnt-icon--support:before {
  content: "\e705";
}
.ubnt-icon--signout:before {
  content: "\e706";
}
.ubnt-icon--settings:before {
  content: "\e707";
}
.ubnt-icon--profile:before {
  content: "\e708";
}
.ubnt-icon--kb:before {
  content: "\e709";
}
.ubnt-icon--inbox:before {
  content: "\e70a";
}
.ubnt-icon--guides:before {
  content: "\e70b";
}
.ubnt-icon--forums:before {
  content: "\e70c";
}
.ubnt-icon--airos-logo:before {
  content: "\e70d";
}
.ubnt-icon--u:before {
  content: "\e70e";
}
.ubnt-icon--bell:before {
  content: "\e70f";
}
.ubnt-icon--dish:before {
  content: "\e710";
}
.ubnt-icon--chat-bubble-2:before {
  content: "\e711";
}
.ubnt-icon--gear-2:before {
  content: "\e712";
}
.ubnt-icon--acc-logo-2:before {
  content: "\e713";
}
.ubnt-icon--acc-logo:before {
  content: "\e714";
}
